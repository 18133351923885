<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="[
      'zcollapse',
      {
        'zcollapse-fullwidth': fullWidth,
        'zcollapse-open': computedOpen,
        'zcollapse-padded': padded,
      },
    ]"
  >
    <div
      v-if="hasTriggerContent"
      :class="['zcollapse-trigger', { 'zcollapse-trigger-disabled': computedDisableTrigger }]"
      @click="toggle"
    >
      <div><slot name="trigger" /></div>
      <fa
        v-if="!computedDisableTrigger"
        :icon="['fas', 'chevron-down']"
        class="transition-transform"
        :class="{ 'rotate-180': computedOpen }"
      />
    </div>
    <div
      v-if="contentHtml"
      ref="collapse"
      :class="[
        'zcollapse-content',
        { 'zcollapse-content-fullwidth': fullWidth, 'zcollapse-content-notrigger': computedDisableTrigger },
        contentClass,
      ]"
      v-html="contentHtml"
    />
    <div
      v-else
      ref="collapse"
      :class="[
        'zcollapse-content',
        { 'zcollapse-content-fullwidth': fullWidth, 'zcollapse-content-notrigger': computedDisableTrigger },
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZCollapse',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    disableTrigger: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    padded: {
      type: Boolean,
      default: false,
    },

    contentHtml: {
      type: String,
      default: null,
    },

    contentClass: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    // if no trigger, rely on visible prop
    computedOpen() {
      return this.computedDisableTrigger ? this.visible : this.isOpen
    },

    hasTriggerContent() {
      return Boolean(this.$slots.trigger)
    },

    computedDisableTrigger() {
      return !this.hasTriggerContent || this.disableTrigger
    },
  },

  mounted() {
    this.isOpen = this.visible
  },

  methods: {
    // called by ZCollapseGroup
    close() {
      this.isOpen = false
    },

    toggle() {
      if (this.$parent.accordion) {
        this.$parent.$emit('close-all')
        this.isOpen = true
        return
      }
      this.isOpen = !this.isOpen
    },
  },
}
</script>
